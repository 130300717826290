<template>
  <div class="banner-container">
    <spinner v-if="isLoading" />
    <h1>Mambo Leo App</h1>
    <div class="login-form">
      <h2>Admin Login</h2>
      <form
        @submit.prevent="login"
      >
        <div class="form-group">
          <label>Email:</label>
          <div class="group">
            <svg
              style="width:24px;height:24px"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z"
              />
            </svg>
            <input
              v-model="user.email"
              type="text"
              class="form-control"
              :class="{ error: $v.user.email.$error || authError.message === authErrorMessage }"
              placeholder="Email"
              @keyup="resetAuthError"
            >
          </div>
        </div>
        <div class="form-group">
          <label>Password:</label>
          <div class="group">
            <svg
              style="width:24px;height:24px"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M18,20V10H6V20H18M18,8A2,2 0 0,1 20,10V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V10A2,2 0 0,1 6,8H15V6A3,3 0 0,0 12,3A3,3 0 0,0 9,6H7A5,5 0 0,1 12,1A5,5 0 0,1 17,6V8H18M12,17A2,2 0 0,1 10,15A2,2 0 0,1 12,13A2,2 0 0,1 14,15A2,2 0 0,1 12,17Z"
              />
            </svg>
            <input
              v-model="user.password"
              type="password"
              class="form-control"
              :class="{ error: $v.user.password.$error || authError.message === authErrorMessage }"
              placeholder="Password"
              @keyup="resetAuthError"
            >
          </div>
        </div>
        <button type="submit">
          Login
        </button>
        <p class="message-text">
          {{ message }}
        </p>
      </form>
    </div>
    <footer>
      <p class="copyright-agileinfo">
        Copyright &copy; {{ currentYear }} Mambo Leo App
      </p>
    </footer>
  </div>
</template>

<script>
  import { required, email } from 'vuelidate/lib/validators'
  import { mapActions, mapState } from 'vuex'
  import spinner from '@/components/layouts/Spinner'

  export default {
    components: {
      spinner,
    },
    data () {
      return {
        user: this.createUserObject(),
        authErrorMessage: 'Username or password may be incorrect',
      }
    },
    validations: {
      user: {
        email: { required, email },
        password: { required },
      },
    },
    computed: {
      ...mapState({
        isLoading: state => state.userData.isLoading,
        isLoggedIn: state => state.userData.isLoggedIn,
        authError: state => state.userData.authError,
        message () {
          return this.$route.query.message
        },
      }),
      currentYear () {
        return new Date().getFullYear()
      },
    },
    methods: {
      ...mapActions('userData', ['loginUser', 'resetAuthError']),
      login () {
        this.resetAuthError()
        this.$v.$touch()
        if (!this.$v.$invalid) {
          this.loginUser({ user: this.user })
            .then(() => {
              if (this.isLoggedIn) {
                this.successRedirect()
              }
            })
        }
      },
      successRedirect () {
        const redirectTo = this.$route.query.redirectTo || { name: 'Dashboard' }
        this.$router.replace(redirectTo)
      },
      createUserObject () {
        return {
          email: this.$route.query.email,
          password: '',
        }
      },
    },
  }
</script>

<style scoped>
body {
    margin: 0;
    padding: 0;
}
.banner-container {
    background: url(./assets/images/cover4.jpg) no-repeat center center;
    min-height: 100vh;
    background-size: cover;
    font-family: 'Raleway', sans-serif;
}
h1 {
    margin: 0;
    color: #fc3955;
    text-align: center;
    font-size: 50px;
    font-weight: 500;
    letter-spacing: 2px;
    padding: 50px 0;
}

h2 {
    margin: 0;
    color: #ffffff;
    font-size: 25px;
    font-weight: 400;
    text-align: center;
    letter-spacing: 1px;
    padding-bottom: 30px;
}

p.register-p {
    color: #eee;
    font-size: 13px;
    text-align: center;
    margin-top: 2em;
}

.login-form {
    background: rgba(0, 0, 0, 0.4117647058823529);
    max-width: 500px;
    margin: 0 auto;
    padding: 3em;
    border-radius: 10px;
    box-sizing: border-box;
}

.group {
    display: flex;
    padding: 15px 5px;
    background-color: #ffffff;
}

.group i {
    color: #fc3955;
    font-size: 20px;
    padding: 0 10px;
}

.form-group {
    margin: 20px 0;
}

.form-group label {
    display: block;
    text-transform: uppercase;
    font-size: 13px;
    color: #d2d2d2;
    letter-spacing: 2px;
    margin-bottom: 10px;
    font-style: italic;
}

input[type="text"],
input[type="password"] {
    width: 100%;
    border: none;
    box-sizing: border-box;
    background: transparent;
    color: #000;
}

input[type="text"]:focus,
input[type="password"]:focus {
    outline: none;
}

button {
    background: #fc3955;
    color: #ffffff;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 1px;
    border: none;
    padding: 12px 60px;
    cursor: pointer;
    width: 100%;
    border-radius: 6px;
}

button:hover {
    background-color: #00BCD4;
    transition-duration: 5s;
}

.forgot {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
}

a {
    color: #eee;
    font-size: 14px;
    text-decoration: none;
}

.forgot p {
    color: #ffffff;
    text-align: center;
    margin: 0px;
    font-size: 13px;
}

.register {
    color: #00BCD4;
    text-decoration: none;
}

/*--footer--*/

p.copyright-agileinfo {
    font-size: 15px;
    letter-spacing: 2px;
    color: #fff;
    font-weight: 300;
}

footer p a {
    display: inline-block;
    color: #fff;
    text-decoration: underline;
}

footer p a:hover {
    color: #fff;
    text-decoration: none;
    -webkit-transition: 0.5s all;
    -o-transition: 0.5s all;
    -moz-transition: 0.5s all;
    -ms-transition: 0.5s all;
    transition: 0.5s all;
}

footer {
    padding: 4em 0;
    text-align: center;
}
/*--//footer--*/

/*-- /responsive design --*/

@media(max-width:768px) {
    h1 {
        font-size: 47px;
    }
}

@media(max-width:667px) {
    h1 {
        font-size: 45px;
    }
}

@media(max-width:640px) {
    p.copyright-agileinfo {
        letter-spacing: 1px;
    }
    h1 {
        font-size: 43px;
    }
}

@media(max-width:568px) {
    h1 {
        font-size: 40px;
    }
    p.copyright-agileinfo {
        font-size: 14px;
    }
}

@media(max-width:480px) {
    .login-form {
    margin: 0 3vw;
}
    h1 {
        font-size: 38px;
    }
    p.copyright-agileinfo {
        line-height: 1.8em;
    }
}

@media(max-width:414px) {
    h1 {
        font-size: 34px;
    }
}

@media(max-width:384px) {
    h1 {
        font-size: 30px;
    }
}

@media(max-width:320px) {
    h1 {
        font-size: 25px;
    }
    .forgot p {
        text-align: left;
    }
    .forgot {
        display: block;
    }
}

.error {
  border: 1px solid #e94b35;
  border-radius: 0.25em;
}
.message-text {
  text-align: center;
  color: #f66;
  margin-top: 1em;
  font-weight: bold;
}
</style>
